import get from "lodash/get";
import { resolveAbsoluteUrlServer } from "utils/route";
import {
  OmneFooterCmsDataProps,
  OmneFooterDataProps,
  OmneFooterSectionsCmsProps,
  OmneFooterSocialMediaLinksCmsProps,
  OmneFooterSubFooterLinksCmsProps,
  OmneFooterSubLinkCmsProps,
} from "../dtos/index.type";

export const getOmneFooterData = (
  cmsData: OmneFooterCmsDataProps,
  languageCode: string,
  numberOfLang: number
): OmneFooterDataProps => {
  if (!cmsData) {
    return {
      uid: "",
      backgroundColor: "",
      socialMediaLinks: [],
      sections: [],
      subFooterLinks: [],
      copyrightText: "",
    };
  }

  return {
    uid: get(cmsData, "uid", ""),
    backgroundColor: get(cmsData, "background_color", ""),
    socialMediaLinks: get(cmsData, "social_media_links", []).map(
      (mediaLink: OmneFooterSocialMediaLinksCmsProps) => ({
        icon: get(mediaLink, "social_media.icon_new", ""),
        link: {
          title: get(mediaLink, "social_media.link.title", ""),
          gaLabel: get(mediaLink, "social_media.link.ga_label", ""),
          href: resolveAbsoluteUrlServer(
            get(mediaLink, "social_media.link.href", ""),
            languageCode,
            numberOfLang
          ),
          openInNewTab: true,
        },
      })
    ),
    sections: get(cmsData, "sections", []).map(
      (section: OmneFooterSectionsCmsProps) => ({
        majorGroupingLink: {
          type: get(section, "major_grouping.major_grouping_link.href", ""),
          title: get(section, "major_grouping.major_grouping_link.title", ""),
        },
        subLinks: get(section, "major_grouping.sub_links", []).map(
          (subLink: OmneFooterSubLinkCmsProps) => ({
            link: {
              title: get(subLink, "sub_link.link.title", ""),
              href: resolveAbsoluteUrlServer(
                get(subLink, "sub_link.link.href", ""),
                languageCode,
                numberOfLang
              ),
              gaLabel: get(subLink, "sub_link.link.ga_label", ""),
              openInNewTab: get(subLink, "sub_link.link.open_in_new_tab", true),
            },
          })
        ),
      })
    ),
    subFooterLinks: get(cmsData, "sub_footer_links", []).map(
      (section: OmneFooterSubFooterLinksCmsProps) => ({
        title: get(section, "link.link.title", ""),
        href: resolveAbsoluteUrlServer(
          get(section, "link.link.href", ""),
          languageCode,
          numberOfLang
        ),
        gaLabel: get(section, "link.link.ga_label", ""),
        openInNewTab: get(section, "link.link.openInNewTab", true),
      })
    ),
    copyrightText: get(cmsData, "copyright_text", ""),
  };
};
