import { replaceContentStackURL } from "@utils";
import {
  CmsAnimatedMarketingBlockData,
  AnimatedMarketingBlockButtonType,
  AnimatedMarketingBlockData,
  Animation,
} from "components/AnimatedMarketingBlock/AnimatedMarketingBlock.type";
import { transformArticleCardListCSDataServer } from "components/ArticleCardList";
import { getGridBlockData } from "components/GridBlock/services/index.service";
import { SchemaTypes } from "components/HeaderSchema";
import { getRecentCardListModernData } from "components/RecentCardListModern/services/index.service";
import {
  CmsRecommendedProductsV2,
  RecommendedProductsV2Props,
} from "components/RecommendedProductsV2/RecommendedProductsV2.type";
import { getReviewCarouselBannerData } from "components/ReviewCarouselBanner/services/index.service";
import { FaqData, UTMFaqData } from "components/InfographicFaq/dtos/index.type";
import { getInfographicFaqData } from "components/InfographicFaq/services/index.service";
import { getInsuranceInfoData } from "components/InsuranceInfo/services/index.service";
import Stack from "contentstack-sdk";
import get from "lodash/get";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import take from "lodash/take";
import moment from "moment";
import { getProductCardV2PropsServerSide } from "services/product-card-v2.service";
import {
  generateSchema,
  SchemaType,
  VideoChannel,
  VideoData,
} from "services/schema.service";
import { resolveAbsoluteUrlServer } from "utils/route";
import { transformTabbedContentSectionData } from "components/TabbedContentSection/TabbedContentSection.service";
import { getTaxCalculationData } from "components/TaxCalculation/utils/index.util";
import { getOmneFooterData } from "components/OmneFooter/services/index.service";

const DATA_PROCESSOR: Record<
  string,
  (
    data: any,
    locale: string,
    blocks?: any[],
    numberOfLang?: number
  ) => Promise<any>
> = {
  announcement_listing: async (data: any) => {
    const csData = data.reference?.[0];
    if (!csData) return data;
    if (!csData.article_category?.length) return data;

    let filterArticleCategories: string | string[] =
      csData.article_category?.[0]?.filter_value;

    if (csData.additional_article && csData.additional_article.length) {
      filterArticleCategories = [
        csData.article_category?.[0]?.filter_value,
        ...csData.additional_article.map(
          (category: any) => category.filter_value
        ),
      ];
    }

    let queryObject = {
      "body.article_block.reference": {
        article_category: {
          filter_value: filterArticleCategories,
        },
      },
    };

    try {
      let pages: any = [];
      let hasMore = true;
      let skip = 0;
      const limit = 100;
      while (hasMore) {
        const results = await Stack.getInstance().getEntriesByReferenceField({
          contentTypeUid: "articles_details",
          queryObject,
          includeReferences: [
            "body.article_block.reference.article_category",
            "body.article_block.reference.article_type",
          ],
          locale: csData.locale,
          jsonRtePath: undefined,
          skip,
          limit,
        });

        if (results.length > 0) {
          pages = [...pages, ...results];
          skip += limit;
        } else {
          hasMore = false;
        }
      }

      const articleTypeMap = {};

      pages.forEach((page: any) => {
        const articleBlock = page.body.find(
          (block: any) => block.article_block !== undefined
        )?.article_block?.reference?.[0];
        page.post_date = articleBlock?.post_date;

        if (articleBlock?.article_type?.length) {
          articleBlock.article_type.map((type: any) => {
            if (type?.title) {
              if (!articleTypeMap[type.title]) {
                articleTypeMap[type.title] = type.title;
              }
            }
          });
        }
      });

      pages = pages.sort(
        (a: any, b: any) =>
          new Date(b.post_date).getTime() - new Date(a.post_date).getTime()
      );

      csData.items = pages;

      let announcementTypes: string[] = [];
      if (articleTypeMap && Object.values(articleTypeMap)?.length) {
        Object.values(articleTypeMap).map((data: any) => {
          if (data) {
            announcementTypes.push(data);
          }
        });
      }

      if (announcementTypes.length && announcementTypes.length > 1) {
        announcementTypes = announcementTypes.sort((a: any, b: any) =>
          a.localeCompare(b)
        );
      }

      data.listAnnouncementTypes = announcementTypes;
    } catch (error) {
      console.log("[EXCEPTION] Fetch Announcement List items");
      console.log(JSON.stringify(error));
    }

    return data;
  },

  article_block: async (data: any) => {
    const csData = data?.reference?.[0];

    if (csData?.hide) {
      return {};
    }

    if (csData?.article_content) {
      const videoBlocks = csData.article_content
        .filter((block: any) => block?.Video_Block)
        .map((block: any) => block.Video_Block);

      const videoDataArray: VideoData[] = [];

      videoBlocks.forEach((videoBlock: any) => {
        let videoData: VideoData | undefined = undefined;

        if (videoBlock && videoBlock.video_id) {
          videoData = {
            type: VideoChannel.YOUTUBE,
            videoId: videoBlock.video_id,
            thumbnailUrl: replaceContentStackURL(
              videoBlock.thumbnail_image?.url
            ),
          };
        }

        if (videoBlock && videoBlock.brightcove_video?.[0]) {
          const brightCoveData = videoBlock.brightcove_video[0];

          videoData = {
            type: VideoChannel.BRIGHTCOVE,
            accountId: brightCoveData.brightcove_account_id,
            videoId: brightCoveData.video_id,
            playerId: brightCoveData.player_id,
            thumbnailUrl: replaceContentStackURL(
              videoBlock.thumbnail_image?.url
            ),
          };
        }

        if (videoData) {
          videoDataArray.push(videoData);
        }
      });

      const schemaDataArray: SchemaTypes[][] = await Promise.all(
        videoDataArray.map((videoData: VideoData) =>
          generateSchema(videoData, SchemaType.VIDEO)
        )
      );

      const nonEmptySchemas: SchemaTypes[][] = schemaDataArray.filter(
        (schemaData) => !isEmpty(schemaData)
      );

      if (nonEmptySchemas.length > 0) {
        data.video_schema = nonEmptySchemas;
      }
    }

    return data;
  },

  top_banner: async (data: any) => {
    const cmsData = data?.reference?.[0];

    let videoData: VideoData | undefined = undefined;

    if (cmsData?.video_file) {
      const mediaAssetData = cmsData.video_file;

      data.video_schema = [
        {
          "@context": "https://schema.org",
          "@type": "VideoObject",
          name: mediaAssetData.title,
          description: mediaAssetData.description,
          thumbnailUrl: replaceContentStackURL(cmsData?.banner_desktop?.url),
          uploadDate: moment(mediaAssetData.updated_at).format("YYYY-MM-DD"),
          contentUrl: replaceContentStackURL(mediaAssetData.url),
        },
      ];

      return data;
    }

    if (cmsData && cmsData.banner_video_id) {
      videoData = {
        type: VideoChannel.YOUTUBE,
        videoId: cmsData.banner_video_id,
        thumbnailUrl: replaceContentStackURL(cmsData?.banner_desktop?.url),
      };
    }

    if (cmsData && cmsData.video_brightcove?.video_id) {
      const brightCoveData = cmsData.video_brightcove;
      videoData = {
        type: VideoChannel.BRIGHTCOVE,
        accountId: brightCoveData.brightcove_account_id,
        videoId: brightCoveData.video_id,
        playerId: brightCoveData.player_id,
        thumbnailUrl: replaceContentStackURL(cmsData?.banner_desktop?.url),
      };
    }

    if (videoData) {
      const schemaData = await generateSchema(videoData, SchemaType.VIDEO);

      if (!isEmpty(schemaData)) {
        data.video_schema = [schemaData];
      }
    }

    return data;
  },

  content_section: async (data: any) => {
    let videoData: VideoData | undefined = undefined;

    if (data && data.youtube_video_id) {
      videoData = {
        type: VideoChannel.YOUTUBE,
        videoId: data.youtube_video_id,
        thumbnailUrl: replaceContentStackURL(data?.image?.url),
      };
    }

    if (data && data.brightcove_video?.[0]) {
      const brightCoveData = data.brightcove_video[0];
      videoData = {
        type: VideoChannel.BRIGHTCOVE,
        accountId: brightCoveData.brightcove_account_id,
        videoId: brightCoveData.video_id,
        playerId: brightCoveData.player_id,
        thumbnailUrl: replaceContentStackURL(data?.image?.url),
      };
    }

    if (videoData) {
      const schemaData = await generateSchema(videoData, SchemaType.VIDEO);

      if (!isEmpty(schemaData)) {
        data.video_schema = [schemaData];
      }
    }

    return data;
  },

  video_listing: async (data: any) => {
    const csData = data?.reference_4?.[0];

    if (csData?.video_data?.length > 0) {
      const videoBlocks = csData.video_data;

      const videoDataArray: VideoData[] = [];

      videoBlocks.forEach((videoBlock: any) => {
        let videoData: VideoData | undefined = undefined;

        if (videoBlock && videoBlock.youtube_video_id) {
          videoData = {
            type: VideoChannel.YOUTUBE,
            videoId: videoBlock.youtube_video_id,
            thumbnailUrl: replaceContentStackURL(
              videoBlock.thumbnail_image?.url
            ),
          };
        }

        if (videoBlock && videoBlock.brightcove_video?.[0]) {
          const brightCoveData = videoBlock.brightcove_video[0];

          videoData = {
            type: VideoChannel.BRIGHTCOVE,
            accountId: brightCoveData.brightcove_account_id,
            videoId: brightCoveData.video_id,
            playerId: brightCoveData.player_id,
            thumbnailUrl: replaceContentStackURL(
              videoBlock.thumbnail_image?.url
            ),
          };
        }

        if (videoData) {
          videoDataArray.push(videoData);
        }
      });

      const schemaDataArray: SchemaTypes[][] = await Promise.all(
        videoDataArray.map((videoData: VideoData) =>
          generateSchema(videoData, SchemaType.VIDEO)
        )
      );

      const nonEmptySchemas: SchemaTypes[][] = schemaDataArray.filter(
        (schemaData) => !isEmpty(schemaData)
      );

      if (nonEmptySchemas.length > 0) {
        data.video_schema = nonEmptySchemas;
      }
    }

    return data;
  },

  Article_Card_List_Section: async (
    data: any,
    locale,
    _blocks,
    numberOfLang
  ) => {
    const csData = data.reference?.[0];

    if (!csData) return data;
    if (!csData.autoselect_mode) {
      try {
        const updatedItems = await Promise.all(
          csData.items?.map(async (item: any) => {
            const results =
              await Stack.getInstance().getEntryByUidByEachReference({
                entryUid: item.uid,
                contentTypeUid: "articles_details",
                referenceFieldPath: [
                  "body.article_block.reference.article_category",
                  "body.article_block.reference.tag",
                  //"body.article_block.reference.article_tags",
                ],
                locale: csData.locale,
              });

            return results;
          })
        );

        csData.items = [...updatedItems];
      } catch (error) {
        console.log(
          "[EXCEPTION] Fetch Article Card List items [without auto select mode]"
        );
        console.log(JSON.stringify(error));
      }

      // cleanup
      //

      // Add transform props to handle new version
      // const data = transformCSData(csData, i18nContext);

      if (csData?.autoselect_mode) {
        csData.items.sort(
          (a, b) =>
            new Date(b.postDate).getTime() - new Date(a.postDate).getTime()
        );
      }

      const transformedData = transformArticleCardListCSDataServer(
        csData,
        locale.split("-")[0],
        numberOfLang || 1
      );

      data.reference[0] = transformedData;

      return data;
    }

    const buildQuery = (fieldName, fieldValue) => ({
      "body.article_block.reference": {
        $in_query: {
          [fieldName]: {
            $in_query: {
              filter_value: {
                $in: fieldValue,
              },
            },
          },
        },
      },
    });

    // NOTE: because of migration issue that we change select_condition from single to multiple
    // so that we need to handle both cases that select_condition can be String or Array
    // Also, during changing, it makes select_condition data can be empty, so that we need to handle
    // the case that select_condition is empty
    if (
      !isArray(csData.select_condition) &&
      !isEmpty(csData.select_condition)
    ) {
      csData.select_condition = [csData.select_condition];
    }

    if (isEmpty(csData.select_condition)) {
      csData.select_condition = [];
      if (!isEmpty(csData.select_category)) {
        csData.select_condition.push("category");
      }
      if (!isEmpty(csData.select_tags)) {
        csData.select_condition.push("tags");
      }
      if (!isEmpty(csData.select_article_type)) {
        csData.select_condition.push("article type");
      }
    }
    if (isEmpty(csData.select_condition)) {
      csData.items = [];
      return data;
    }

    const queryObject = {
      $and: csData.select_condition.map((selectBy) => {
        switch (selectBy) {
          case "category":
            return buildQuery(
              "article_category",
              csData.select_category?.map((t) => t.filter_value)
            );
          case "tags":
            return buildQuery(
              "tag",
              csData.select_tags?.map((t) => t.filter_value)
            );
          case "article type":
            return buildQuery(
              "article_type",
              csData.select_article_type?.map((t) => t.filter_value)
            );
        }
      }),
    };

    // maxPages = 0 mean pick all
    const maxPages = csData.number_of_cards || 0;

    try {
      let pages: any = [];
      let hasMore = true;
      let skip = 0;
      const limit = 100;
      while (hasMore) {
        const results = await Stack.getInstance().getEntriesByReferenceField({
          contentTypeUid: "articles_details",
          queryObject,
          useRawQuery: true,
          includeReferences: [
            "body.article_block.reference.article_category",
            "body.article_block.reference.tag",
          ],
          locale: csData.locale,
          jsonRtePath: undefined,
          limit,
          skip,
        });

        if (results.length > 0) {
          pages = [...pages, ...results];
          skip += limit;
        } else {
          hasMore = false;
        }
      }

      const getPostDate = (csData) =>
        csData.body?.find((l: any) => !!l?.article_block?.reference)
          ?.article_block?.reference?.[0]?.post_date || null;

      pages.sort(
        (a, b) =>
          new Date(getPostDate(b)).getTime() -
          new Date(getPostDate(a)).getTime()
      );

      if (maxPages) {
        pages = take(pages, maxPages);
      }

      csData.items = pages;
    } catch (error) {
      console.log("[EXCEPTION] Fetch Article Card List items");
      console.log(error);
      console.log(JSON.stringify(error));
    }

    const transformedData = transformArticleCardListCSDataServer(
      csData,
      locale.split("-")[0],
      numberOfLang || 1
    );

    data.reference[0] = transformedData;
    return data;
  },

  dynamic_card_list_block: async (data: any) => {
    if (!data || !data.autoselect_mode) {
      return data;
    }
    let queryObject: object = {};
    let locale;

    if (data.select_condition === "category") {
      queryObject = {
        "body.article_block.reference": {
          article_category: {
            filter_value: data.select_category?.[0]?.filter_value,
          },
        },
      };
      locale = data.select_category?.[0]?.locale;
    } else if (data.select_condition === "tags") {
      queryObject = {
        "body.article_block.reference": {
          tag: {
            filter_value: data.select_tags?.map((t: any) => t.filter_value),
          },
        },
      };
      locale = data.select_tags?.[0]?.locale;
    }

    try {
      let pages: any = [];
      let hasMore = true;
      let skip = 0;
      const limit = 100;
      while (hasMore) {
        const results = await Stack.getInstance().getEntriesByReferenceField({
          contentTypeUid: "articles_details",
          queryObject,
          includeReferences: [
            "body.article_block.reference.article_category",
            "body.article_block.reference.tag",
          ],
          locale,
          jsonRtePath: undefined,
          limit,
          skip,
        });
        if (results.length > 0) {
          pages = [...pages, ...results];
          skip += limit;
        } else {
          hasMore = false;
        }
      }

      data.items = pages;
    } catch (error) {
      console.log("[EXCEPTION] Fetch Dynamic Card List data");
      console.log(JSON.stringify(error));
    }

    return data;
  },

  campaign_card_section: async (data: any) => {
    const csData = data.reference?.[0];
    if (!csData?.autoselect_mode) return data;
    let locale;
    let queryObject: object = {};

    if (csData.select_condition === "category") {
      const selectedCategory = get(csData, "select_category[0]", {});
      queryObject = {
        "body.article_block.reference": {
          article_category: {
            filter_value: [selectedCategory.filter_value],
          },
        },
      };
      locale = selectedCategory.locale;
    } else if (csData.select_condition === "tags") {
      queryObject = {
        "body.article_block.reference": {
          tag: {
            filter_value: csData.select_tags?.map((t: any) => t.filter_value),
          },
        },
      };
      locale = get(csData, "select_tags[0].locale");
    }

    try {
      csData.items = [];

      let pages: any = [];
      let hasMore = true;
      let skip = 0;
      const limit = 100;
      while (hasMore) {
        const results = await Stack.getInstance().getEntriesByReferenceField({
          contentTypeUid: "articles_details",
          queryObject,
          includeReferences: [
            "body.article_block.reference.article_category",
            "body.article_block.reference.tag",
          ],
          locale: locale || "en-us",
          jsonRtePath: undefined,
          limit,
          skip,
        });
        if (results.length > 0) {
          pages = [...pages, ...results];
          skip += limit;
        } else {
          hasMore = false;
        }
      }

      csData.items = pages;
    } catch (error) {
      console.log("[EXCEPTION] Fetch Campaign Card List items");
      console.log(JSON.stringify(error));
    }

    return data;
  },
  recommended_products: async (data: any, locale = "en-us") => {
    try {
      let allProducts = await Stack.getInstance().getEntries({
        contentTypeUid: "product_details",
        locale: locale,
        referenceFieldPath: [
          "body.product_metadata.type",
          "body.product_metadata.channel",
          "body.top_banner.reference",
          //"body.product_metadata.social_reference",
          "body.product_metadata.insurance_category_v2",
        ],
        jsonRtePath: undefined,
      });

      allProducts = allProducts?.[0];
      const productArgs = data as any;

      let filteredByCategoryProducts = [];
      let filteredByTagProducts = [];

      if (allProducts && productArgs) {
        const minifiedProducts = allProducts.map((x: any) => {
          const productMetadata = x?.body?.find(
            (y: any) => y["product_metadata"]
          );
          const relatedProducts = x?.body?.find(
            (y: any) => y["recommended_products"]
          );

          return {
            insuranceCategory:
              productMetadata?.product_metadata?.insurance_category,
            tags: productMetadata?.product_metadata?.product_tags?.value?.map(
              (x: any) => x.value
            ),
            uid: x.uid,
            csId: relatedProducts?.recommended_products?._metadata.uid,
          };
        });

        const currentPDP = minifiedProducts?.find(
          (x: any) => x.csId === productArgs._metadata.uid
        );

        if (productArgs.category_filter?.auto_filter_by_category) {
          let listUids: string[] = [];
          if (!productArgs.category_filter.insurance_category?.length) {
            listUids = minifiedProducts
              ?.filter((x: any) => {
                let flag = false;

                currentPDP?.insuranceCategory?.map((y: any) => {
                  if (x?.insuranceCategory?.includes(y)) {
                    flag = true;
                  }
                });
                return flag;
              })
              ?.map((x: any) => x.uid);
          } else {
            listUids = minifiedProducts
              ?.filter((x: any) =>
                x?.insuranceCategory?.includes(
                  productArgs.category_filter?.insurance_category?.[0]
                    ?.filter_value
                )
              )
              .map((x: any) => x.uid);
          }

          filteredByCategoryProducts = allProducts?.filter((x: any) =>
            listUids.includes(x.uid)
          );
        }

        if (productArgs.tag_filter?.auto_filter_by_tags) {
          let listUids: string[] = [];
          if (!productArgs.tag_filter.product_tags?.length) {
            listUids = minifiedProducts
              ?.filter((x: any) => {
                let flag = false;
                x.tags?.forEach((tag: string) => {
                  if (currentPDP.tags.includes(tag)) {
                    flag = true;
                  }
                });

                return flag;
              })
              .map((x: any) => x.uid);
          } else {
            listUids = minifiedProducts
              ?.filter((x: any) => {
                let flag = false;
                x.tags?.forEach((tag: string) => {
                  if (productArgs.tag_filter.product_tags.includes(tag)) {
                    flag = true;
                  }
                });

                return flag;
              })
              .map((x: any) => x.uid);
          }

          filteredByTagProducts = allProducts?.filter((x: any) =>
            listUids.includes(x.uid)
          );
        }
      }
      const result = [
        ...productArgs.products
          ?.map((p: any) => p?.reference?.[0])
          ?.filter((p: any) => !!p),
        ...filteredByCategoryProducts,
        ...filteredByTagProducts,
      ];

      if (result?.length) {
        data.relatedProducts = [
          ...result
            ?.filter(
              (p: any, index: number, self: any) =>
                index === self.findIndex((t: any) => t.uid === p.uid)
            )
            ?.filter(
              (p: any) =>
                p?.body?.find((y: any) => y["recommended_products"])
                  ?.recommended_products?._metadata.uid !==
                productArgs._metadata.uid
            ),
        ];
      }

      if (data.enable_api) {
        data.relatedProducts = data.relatedProducts.map((p) => p.uid);
      }

      // WORKAROUND:
      // Fixing ContentStack does not return insurance_category_v2 in product metadata
      // will be investigate later
      const categoryProducts = allProducts?.reduce((acc, product) => {
        const productMetadata = product?.body?.find(
          (obj: any) => obj.product_metadata
        )?.product_metadata;

        if (productMetadata) {
          acc[product.uid] = productMetadata.insurance_category_v2;
        }
        return acc;
      }, {});

      data.products?.forEach((p) => {
        const product = p.reference[0];
        const productMetadata = product?.body?.find(
          (obj: any) => obj.product_metadata
        )?.product_metadata;

        if (productMetadata?.insurance_category_v2) {
          productMetadata.insurance_category_v2 = categoryProducts[product.uid];
        }
      });

      delete data.products;
    } catch (error) {
      console.log("[EXCEPTION] Fetch recommended products items");
      console.log(error);
    }

    return data;
  },
  article_preview: async (csData: any, locale = "en-us") => {
    if (
      (!csData?.list_articles?.auto?.auto_filter_by_category ||
        !csData?.list_articles?.auto?.category?.[0]?.filter_value) &&
      (!csData?.list_articles?.auto?.auto_filter_by_tag ||
        !csData?.list_articles?.auto?.tag?.length)
    ) {
      return csData;
    }

    let queryObject = {
      "body.article_block.reference": {},
    };

    if (
      csData?.list_articles?.auto?.auto_filter_by_category &&
      csData?.list_articles?.auto?.category?.[0]?.filter_value
    ) {
      queryObject["body.article_block.reference"]["article_category"] = {
        filter_value: csData.list_articles.auto.category[0].filter_value,
      };
    }

    if (
      csData?.list_articles?.auto?.auto_filter_by_tag &&
      csData?.list_articles?.auto?.tag?.length
    ) {
      queryObject["body.article_block.reference"]["tag"] = {
        filter_value: csData.list_articles.auto.tag.map(
          (t: any) => t.filter_value
        ),
      };
    }

    try {
      let pages: any = [];
      let hasMore = true;
      let skip = 0;
      const limit = 100;
      while (hasMore) {
        const results = await Stack.getInstance().getEntriesByReferenceField({
          contentTypeUid: "articles_details",
          queryObject,
          includeReferences: [
            "body.article_block.reference.article_category",
            "body.article_block.reference.tag",
          ],
          locale,
          jsonRtePath: undefined,
          skip,
          limit,
        });

        if (results.length > 0) {
          pages = [...pages, ...results];
          skip += limit;
        } else {
          hasMore = false;
        }
      }

      pages.forEach((page: any) => {
        if (page) {
          const articleBlock = page.body?.find(
            (block: any) => block.article_block !== undefined
          )?.article_block?.reference?.[0];
          page.post_date = articleBlock?.post_date;
          page.display_title = articleBlock?.display_title;

          if (articleBlock?.announcement_view_type === "pdf") {
            page.url = replaceContentStackURL(
              articleBlock?.article_content?.[0]?.pdf_block?.pdf_file?.url
            );
          }
        }
      });
      pages = pages.sort(
        (a: any, b: any) =>
          new Date(b.post_date).getTime() - new Date(a.post_date).getTime()
      );

      csData.allArticles = pages.slice(0, 3);
    } catch (error) {
      console.log("[EXCEPTION] Fetch Announcement List items");
      console.log(JSON.stringify(error));
    }

    return csData;
  },
  recent_card_list: async (data: any, locale = "en-us") => {
    const csData = data.reference?.[0];

    if (!csData) return csData;
    if ((csData?.selected_category || []).length === 0) {
      csData.items?.forEach((page: any) => {
        if (page) {
          const articleBlock = page?.body?.find(
            (block: any) => block?.article_block !== undefined
          )?.article_block?.reference?.[0];
          page.post_date = articleBlock?.post_date;
        }
      });
      return csData;
    }
    let queryObject: object = {};

    queryObject = {
      "body.article_block.reference": {
        article_category: {
          filter_value: csData?.selected_category?.[0]?.filter_value,
        },
      },
    };

    try {
      let pages: any = [];
      let hasMore = true;
      let skip = 0;
      const limit = 100;
      while (hasMore) {
        const results = await Stack.getInstance().getEntriesByReferenceField({
          contentTypeUid: "articles_details",
          queryObject,
          includeReferences: [
            "body.article_block.reference.article_category",
            "body.article_block.reference.tag",
          ],
          locale: csData.locale,
          jsonRtePath: undefined,
          limit,
          skip,
        });
        if (results.length > 0) {
          pages = [...pages, ...results];
          skip += limit;
        } else {
          hasMore = false;
        }
      }

      pages.forEach((page: any) => {
        if (page) {
          const articleBlock = page?.body?.find(
            (block: any) => block?.article_block !== undefined
          )?.article_block?.reference?.[0];
          page.post_date = articleBlock?.post_date;
        }
      });

      pages = pages.sort(
        (a: any, b: any) =>
          new Date(b.post_date).getTime() - new Date(a.post_date).getTime()
      );

      csData.items = pages;
    } catch (error) {
      csData.items = [];
      console.log("[EXCEPTION] Fetch Article Card List items");
      console.log(JSON.stringify(error));
    }

    return csData;
  },
  Products_Section: async (csData: any, locale = "en-us") => {
    if (csData.api_enabled) {
      csData.product_ids = csData.products.map((product) => product.uid);
      delete csData.products;
    } else {
      csData.product_ids = [];
    }
    return csData;
  },
  legacy_products_section: async (csData: any, locale = "en-us") => {
    if (csData.api_enabled) {
      csData.product_ids = csData.products.map((product) => product.uid);
      delete csData.products;
    } else {
      csData.product_ids = [];
    }
    return csData;
  },
  flexible_form_v2: async (csData: any, locale = "en-us", blocks?: any[]) => {
    const data = csData.reference?.[0] || {};

    data.emailTemplates = data.form_settings?.email_template?.map((e: any) => {
      return { template: e.template, uid: e.uid };
    });

    delete data.form_settings?.email_template;

    const productMetadata = blocks?.find(
      (block) => !!block.product_metadata
    )?.product_metadata;

    if (productMetadata) {
      csData.productMetadata = {
        productChannel:
          productMetadata?.channel?.[0]?.form_value ||
          productMetadata?.channel?.[0]?.title,
      };
    }

    return csData;
  },

  corp_article_grid_block: async (data: any, locale = "en-us") => {
    const csData = data.reference?.[0];
    if (!csData) return data;

    const categories = csData.categories.map(
      (category: any) => category.filter_value
    );

    const tags = csData.tags.map((tag: any) => tag.filter_value) || [];

    let queryObject = {};

    if (categories.length > 0) {
      queryObject["body.article_block.reference"] = {
        $in_query: {
          article_category: {
            $in_query: {
              filter_value: {
                $in: categories,
              },
            },
          },
        },
      };
    }

    if (tags.length > 0) {
      const tagQuery = {
        "body.article_block.reference": {
          $in_query: {
            tag: {
              $in_query: {
                filter_value: {
                  $in: tags,
                },
              },
            },
          },
        },
      };
      if (queryObject["body.article_block.reference"]) {
        queryObject = {
          $or: [queryObject, tagQuery],
        };
      } else {
        queryObject = tagQuery;
      }
    }

    try {
      const response = await Stack.getInstance().getEntriesByCustomQuery(
        locale,
        "articles_details",
        (query) => {
          query.query(queryObject);
        },
        [
          "page_type",
          "body.article_block.reference",
          "body.article_block.reference.article_category",
          "body.article_block.reference.tag",
        ],
        true
      );

      if (csData.tags) {
        delete csData.tags;
      }

      return {
        reference: [
          {
            ...csData,
            categories: [
              {
                title: csData.all_category_text || "All",
                filter_value: "all",
                icon: csData.all_category_icon || "document",
              },
              ...csData.categories,
            ],
            articles: response,
            button: csData.button || {
              text: "Show More",
              icon: "plus",
              url: "",
            },
            result_text: csData.result_text || "{{total}} result(s) found.",
            no_result_text: csData.no_result_text || "No result found.",
            show_more_text: csData.show_more_text || "Show More",
            clear_all_text: csData.clear_all_text || "Clear All",
          },
        ],
      };
    } catch (error) {
      console.log("[EXCEPTION] Fetch Article Grid Block");
      console.log(JSON.stringify(error));
      return data;
    }
  },
  marketing_carousel_banner: async (data: any, locale = "en-us") => {
    try {
      let allProducts = await Stack.getInstance().getEntries({
        contentTypeUid: "product_details",
        locale: locale,
        referenceFieldPath: [
          "body.product_metadata.type",
          "body.product_metadata.channel",
          "body.top_banner.reference",
          "body.product_metadata.insurance_category_v2",
        ],
        jsonRtePath: undefined,
      });

      allProducts = allProducts?.[0];
      let items = get(
        data,
        "reference[0].reference_tabs[0].tabs.tab_item[0].content.reference[0].items",
        null
      );
      for (let i = 0; i < items?.length || 0; i++) {
        const item = items[i];

        if (item) {
          const reference = get(item, "reference[0]", null);

          if (reference) {
            reference.form_settings.email_template = (
              await Promise.all(
                get(reference, "form_settings.email_template", []).map(
                  (emailTemplate) => {
                    return Stack.getInstance().getEntryByUid({
                      contentTypeUid: "email_template",
                      locale,
                      referenceFieldPath: [],
                      jsonRtePath: undefined,
                      entryUid: emailTemplate.uid,
                    });
                  }
                )
              )
            ).flatMap((e) => {
              return e.map((e) => ({
                template: e.template,
                uid: e.uid,
              }));
            });
          }
        }
      }
      const reference = get(data, "reference[0]", {});
      const metadata = get(data, "_metadata", {});
      const categoryProducts = allProducts?.reduce((acc, product) => {
        const productMetadata = product.body?.find(
          (obj: any) => obj.product_metadata
        )?.product_metadata;

        if (productMetadata) {
          acc[product.uid] = productMetadata.insurance_category_v2;
        }

        return acc;
      }, {});

      return {
        reference: [
          {
            ...reference,
            categoryProducts,
          },
        ],
        _metadata: { ...metadata },
      };
    } catch (error) {
      console.log("[EXCEPTION] Fetch Marketing Carousel Banner items");
      console.log(error);
    }

    return data;
  },
  corp_featured_product_list: async (csData: any, locale = "en-us") => {
    const blockData = csData.reference?.[0];
    if (!blockData) return csData;
    if (blockData.api_enabled) {
      blockData.product_ids = blockData.products.map((product) => product.uid);
      delete blockData.products;
    } else {
      blockData.product_ids = [];
    }

    csData.reference[0] = blockData;

    return csData;
  },
  corp_claim_guide_question_block: async (csData: any, locale = "en-us") => {
    const blockData = csData.reference_2?.[0];

    // WORKAROUND: Due to error from contentstack that not return
    // the url for image, so I need to fetch manually and inject into the csData
    const csResponse = await Stack.getInstance().getEntries({
      contentTypeUid: "corp_claim_guide_question_block",
      locale: locale,
      referenceFieldPath: [],
      jsonRtePath: undefined,
      customQuery: (query) => {
        query.entry_uid = blockData.uid;
      },
    });

    const csBlockData = csResponse?.[0]?.[0];

    blockData.custom_ui.image = csBlockData?.custom_ui?.image;

    return csData;
  },
  horizonal_tab: async (csData: any) => {
    try {
      const data = csData?.reference?.[0];
      if (!data) return csData;

      const faqSchemaData: any = [];

      data.tab_option?.forEach((tab: any) => {
        if (!tab || !tab.component || !tab.component.length) return;

        tab.component.forEach((component: any) => {
          if (
            component.reference?.[0]?.["_content_type_uid"] === "corp_faq_list"
          ) {
            if (component.reference?.[0]?.global) {
              const componentData = component.reference?.[0]?.global;
              const faqGroups = componentData?.faq_groups;
              componentData.faq_schema = [];

              if (faqGroups && faqGroups.length) {
                faqSchemaData.push(
                  faqGroups
                    .map((faqGroup: any) => {
                      return faqGroup?.group
                        ?.map((faq: any) => ({
                          question: faq?.question,
                          answer: faq?.answer,
                        }))
                        ?.filter((faq: any) => faq.question && faq.answer);
                    })
                    ?.flat()
                );
              }
            }
          }
        });
      });

      const faqSchemaDataFlatten = faqSchemaData?.flat();

      const schemaData = await generateSchema(
        faqSchemaDataFlatten,
        SchemaType.FAQ
      );

      csData.faq_schema = schemaData;

      return csData;
    } catch (error) {
      console.log(
        "[Processor][Faq List in Horizontal Tab] Failed calling api generate faq schema"
      );
      console.log(error);
    }
  },
  support_section: async (csData: any) => {
    try {
      const data = csData?.faqs_reference?.[0];
      if (!data) return csData;

      let schema: SchemaTypes[] = [];

      if (data.faqs && data.faqs.length) {
        schema = await generateSchema(
          data.faqs
            .map((faq: any) => ({
              question: faq?.question,
              answer: faq?.answer,
            }))
            .filter((faq: any) => faq.question && faq.answer),
          SchemaType.FAQ
        );

        data.faq_schema = [schema];
      }

      return csData;
    } catch (error) {
      console.log(
        "[Processor][Faq Supoprt Section] Failed calling api generate faq schema"
      );
      console.log(error);
    }
  },
  faq_list: async (csData: any) => {
    try {
      const faqGroups = csData?.faq_groups;

      if (!faqGroups || !faqGroups.length) return csData;

      let schemaData: SchemaTypes[] = [];

      if (faqGroups && faqGroups.length) {
        schemaData = await generateSchema(
          faqGroups
            .map((faqGroup: any) => {
              return faqGroup?.group
                ?.map?.((faq: any) => ({
                  question: faq?.question,
                  answer: faq?.answer,
                }))
                ?.filter((faq: any) => faq.question && faq.answer);
            })
            ?.flat(),
          SchemaType.FAQ
        );
      }

      csData.faq_schema = schemaData;

      return csData;
    } catch (error) {
      console.log(
        "[Processor][Faq List] Failed calling api generate faq schema"
      );
      console.log(error);
    }
  },
  insurance_recommended_products: async (csData: any) => {
    const blockData = csData.reference_2?.[0];
    if (!blockData) return csData;
    if (!blockData.enable_api) return csData;
    blockData.insurance_types = blockData.insurance_types.map((t) => {
      const productIds = t.products.map((p) => p.uid);
      delete t.products;
      return {
        ...t,
        products: productIds,
      };
    });

    return csData;
  },
  corp_recommended_products_v2: async (
    csData: CmsRecommendedProductsV2,
    locale: string,
    blocks?: any[],
    numberOfLang?: number
  ): Promise<RecommendedProductsV2Props> => {
    const blockData = csData.reference_5[0];
    if (!blockData) {
      return {
        title: "",
        tabs: [],
        button: {
          url: "",
          icon: "",
          label: "",
          gaLabel: "",
          openInNewTab: false,
        },
        priceLabel: "",
      };
    }

    const priceFormat = blockData.price_format;

    return {
      title: blockData.display_title || blockData.title,
      tabs: blockData.tabs.map((tab) => {
        return {
          uid: tab._metadata.uid,
          name: tab.name,
          products: tab.products.map((product) => {
            const props = getProductCardV2PropsServerSide(
              product,
              locale,
              numberOfLang || 2
            );
            return {
              ...props,
              showPrice: true,
              price: priceFormat.replace(
                "{productPrice}",
                `<span>${props.price || "550"}</span>`
              ),
              priceLabel: blockData.price_label,
            };
          }),
        };
      }),
      button: {
        url: resolveAbsoluteUrlServer(
          blockData.button.url,
          locale,
          numberOfLang || 2
        ),
        icon: blockData.button.icon,
        label: blockData.button.label,
        gaLabel: blockData.button.ga_label,
        openInNewTab: blockData.button.open_in_new_tab,
      },
      priceLabel: blockData.price_label,
    };
  },
  review_carousel_banner: async (
    cmsData: any,
    locale,
    _blocks,
    numberOfLang
  ) => {
    return getReviewCarouselBannerData(cmsData, locale, numberOfLang || 1);
  },
  corp_recent_card_list: async (
    cmsData: any,
    locale,
    _blocks,
    numberOfLang
  ) => {
    return getRecentCardListModernData(
      cmsData,
      locale.split("-")[0],
      numberOfLang || 1
    );
  },
  corp_animated_marketing_block: async (
    csData: CmsAnimatedMarketingBlockData,
    locale: string,
    _blocks?: any[],
    numberOfLang?: number
  ): Promise<AnimatedMarketingBlockData> => {
    const blockData = csData.reference_5[0];
    if (!blockData) {
      return {
        title: "",
        buttons: [],
        appIcon: "",
        description: "",
        animatedImages: {
          animation: Animation.PHHomePage,
          layers: [],
        },
      };
    }
    return {
      title: blockData.display_title || blockData.title,
      description: blockData.description,
      appIcon: replaceContentStackURL(blockData.app_icon.url),
      buttons: blockData.buttons.map((button) => ({
        type: button.type,
        url: resolveAbsoluteUrlServer(button.url, locale, numberOfLang || 2),
        gaLabel: button.ga_label,
        label: button.label,
        openInNewTab: button.open_in_new_tab,
        buttonImage: replaceContentStackURL(button.button_image.url),
      })),
      animatedImages: {
        animation: blockData.animated_images.animation,
        layers: blockData.animated_images.layers.map((layer) => ({
          images: layer.images.map((image) => ({
            imageUrl: replaceContentStackURL(image.image.url),
            title: image.title,
            subtitle: image.subtitle,
            openInNewTab: true,
            navigateUrl: image.url
              ? resolveAbsoluteUrlServer(image.url, locale, numberOfLang || 2)
              : "",
          })),
        })),
      },
    };
  },

  corp_grid_block: async (cmsData: any, locale, _blocks, numberOfLang) => {
    return getGridBlockData(cmsData, locale.split("-")[0], numberOfLang || 1);
  },

  corp_insurance_info: async (cmsData: any) => {
    return getInsuranceInfoData(cmsData);
  },
  corp_infographic_faq: async (cmsData: any, locale, _blocks, numberOfLang) => {
    const data = getInfographicFaqData(
      cmsData,
      locale.split("-")[0],
      numberOfLang || 1
    );

    const faqSchema = data.faq.map((faq: FaqData) => ({
      question: faq.question,
      answer: faq.answer,
    })) as any;

    data.utmContent.map((content: UTMFaqData) => {
      content.faq.map((faq: FaqData) => {
        faqSchema.push({
          question: faq.question,
          answer: faq.answer,
        });
      }) as any;
    });

    const schemaData = await generateSchema(faqSchema, SchemaType.FAQ);

    data.faq_schema = schemaData;

    return data;
  },
  corp_tabbed_content_section: async (
    cmsData: any,
    locale,
    _blocks,
    numberOfLang
  ) => {
    return transformTabbedContentSectionData(
      cmsData,
      locale,
      numberOfLang || 2
    );
  },
  tax_calculation: async (cmsData: any, locale, _blocks, numberOfLang) => {
    return getTaxCalculationData(
      cmsData,
      locale.split("-")[0],
      numberOfLang || 1
    );
  },

  omne_web_footer: async (cmsData: any, locale, _blocks, numberOfLang) => {
    return getOmneFooterData(cmsData, locale.split("-")[0], numberOfLang || 1);
  },
};

const CONTENT_TYPE_REMAPPER = {
  bltbf99fa94a8773b0d: "corp_featured_product_list",
};
export const getDataProcessor = (
  typeName: string
):
  | ((
      data: any,
      locale: string,
      blocks: any,
      numberOfLang: number
    ) => Promise<any>)
  | null => {
  if (CONTENT_TYPE_REMAPPER[typeName]) {
    typeName = CONTENT_TYPE_REMAPPER[typeName];
  }

  const processor = DATA_PROCESSOR[typeName];

  return processor || null;
};
